<template>
  <div>
    <div class="border border-solid border-blue-200">
      <datatable
        :data="loans.data"
        :columns="loans.columns"
        :query="searchQuery"
        :loading="loans.loading"
        :on-click="click"
        :exportable="true"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/admin/personal/loans?review=officers_review`"
        :search-field="searchField"
        :actions="actions"
        :limit="30"
        ref="table"
        @selection-update="selectionUpdate"
      />
    </div>

    <modals-user
      ref="viewModal"
      :selected-user="selectedUser"
      :allow-delete="false"
    />

    <modal className="w-full md:w-2/5 xl:w-3/10 pt-10" ref="declineModal">
      <h4 class="text-2xl font-bold mb-16">Decline Loan</h4>

      <div>
        <template v-if="actionError">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon mr-3">!</span>
            <span>{{ actionError }}</span>
          </div>
        </template>
        <template v-if="selectedLoan">
          <p class="text-gray-500">Name</p>
          <div class="rounded p-2 h-12">
            <div class="text-xl font-bold">
              {{ selectedLoan.user.name }}
              {{ selectedLoan.user.last_name }}
            </div>
          </div>

          <p class="text-gray-500 mt-4">Amount Requested</p>
          <div class="bg-blue-200 rounded p-2 text-center mb-4">
            <div class="text-xl font-bold">
              ₦{{ selectedLoan.loan_amount | currency }}
            </div>
          </div>
        </template>

        <div class="text-center">
          <button
            @click.prevent="confirmDecline"
            class="button bg-blue-500 text-white hover:bg-blue-600"
            :disabled="false"
          >
            <template v-if="loading">Declining...</template>
            <template v-else>Confirm</template>
          </button>
        </div>
      </div>
    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 pt-10" ref="officerVerifyModal">
      <h4 class="text-2xl font-bold mb-16">Account Officer Verify Loan</h4>

      <div>
        <template v-if="actionError">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon mr-3">!</span>
            <span>{{ actionError }}</span>
          </div>
        </template>
        <template v-if="selectedLoan">
          <p class="text-gray-500">Name</p>
          <div class="rounded p-2 h-12">
            <div class="text-xl font-bold">
              {{ selectedLoan.user.name }}
              {{ selectedLoan.user.last_name }}
            </div>
          </div>

          <p class="text-gray-500 mt-4">Amount Requested</p>
          <div class="bg-blue-200 rounded p-2 text-center mb-4">
            <div class="text-xl font-bold">
              ₦{{ selectedLoan.loan_amount | currency }}
            </div>
          </div>
        </template>

        <div class="text-center">
          <button
            @click.prevent="confirmOfficerVerify"
            class="button bg-blue-500 text-white hover:bg-blue-600"
            :disabled="false"
          >
            <template v-if="loading">Verifying...</template>
            <template v-else>Confirm</template>
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-full md:w-3/5 xl:w-4/10 pt-10"
      ref="stopUserFromRequestingLoanModal"
      @close="removeError"
    >
      <template v-if="actionError">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon mr-3">!</span>
          <span>{{ actionError }}</span>
        </div>
      </template>
      <h6 v-if="selectedLoan" class="text-xl font-bold mb-6">
        Stop {{ selectedLoan.user.name }} {{ selectedLoan.user.last_name }} from
        requesting loan
      </h6>
      <div class="flex-grow mb-4">
        <form>
          <form-group
            type="textarea"
            name="comment"
            :form="stopLoanRequestForm"
            v-model="stopLoanRequestForm.data.comment.value"
          >
            Comments.
          </form-group>

          <form-group
            type="date"
            leftIcon="calendar-outline"
            name="nextLoanRequest"
            :form="stopLoanRequestForm"
            v-model="stopLoanRequestForm.data.nextLoanRequest.value"
          >
            Date Of Next Request
          </form-group>

          <button
            type="button"
            class="btn btn-sm btn-blue ml-auto"
            :disabled="loading"
            @click.prevent="confirmStopUserLoanRequest"
          >
            <span v-if="loading">Saving...</span>
            <span v-else>Save</span>
          </button>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    searchField: {
      type: [HTMLInputElement],
      default: null
    }
  },
  data() {
    return {
      // selection: [],
      // checkmark: require('@/assets/checkmark-base.svg'),
      lenders: this.$options.resource([]),
      loans: this.$options.resource([], {
        selected: null,
        columns: [
          {
            name: 'id',
            th: 'Loan ID'
          },
          {
            name: 'name',
            th: 'User Name',
            render: loan => `${loan.user?.name} ${loan.user?.last_name || ''}`
          },
          {
            name: 'company',
            th: 'Company',
            render: loan => loan.user.company?.name
          },
          {
            name: 'requested_amount',
            th: 'Amount Requested',
            render: loan =>
              `₦ ${this.$options.filters.currency(loan?.requested_amount)}`
          },
          {
            name: 'loan_amount',
            th: 'Amount to disburse',
            render: loan =>
              `₦ ${this.$options.filters.currency(loan.loan_amount)}`
          },
          {
            name: 'tenure',
            th: 'Tenure',
            render: loan => `${loan?.tenure} Months`
          },
          {
            name: 'interest_rate',
            th: 'Interest Rate'
          },
          {
            name: 'email',
            th: 'Bank Account',
            render: loan => loan.user.profile?.account_no
          },
          {
            name: 'phone',
            th: 'Bank Name',
            render: loan =>
              this.$options.filters.bankName(
                loan.user.profile?.bank_name,
                this.banks
              )
          },
          {
            name: 'salary_day',
            th: 'Salary Day',
            render: loan => loan?.user?.profile?.salary_day || 'N/A'
          },
          {
            name: 'status',
            th: 'Status',
            render: (loan, status) => {
              switch (status) {
                case 'approved':
                  return `<div class="badge badge-green-soft-outline">
											Approved
										</div>`;
                case 'accepted':
                  return `<div class="badge badge-green-soft-outline">
											Accepted
										</div>`;
                case 'disbursed':
                  return `<div class="badge badge-green-soft-outline">
											Disbursed
										</div>`;
                case 'declined':
                  return `<div class="badge badge-red-soft-outline">
											Declined
										</div>`;
                case 'rejected':
                  return `<div class="badge badge-red-soft-outline">
											Rejected
										</div>`;
                case 'paid':
                  return `<div class="badge badge-green-soft-outline">
											Paid
										</div>`;
                case 'pending':
                default:
                  return `<div class="badge badge-orange-soft-outline">
											Pending
										</div>`;
              }
            }
          }
        ],
        successes: [],
        failures: []
      }),
      getLoanData: this.$options.resource({}),
      loading: false,
      selectedLoan: null,
      actionError: null,
      successMessage: null,
      actions: [
        {
          text: 'Decline Loan',
          class:
            'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
          action: this.openDeclinedModal
          // disabled: loan =>
          //   loan.status === "paid" || loan.status === "transferred"
        },
        {
          text: 'Officer Verify',
          class:
            'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
          action: this.openOfficerVerifyModal
        },
        {
          text: 'Stop Loan Request',
          class:
            'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
          action: this.openStopUserFromRequestingLoanModal
        }
      ],
      stopLoanRequestForm: this.$options.basicForm([
        {
          name: 'comment',
          rules: 'required'
        },
        {
          name: 'nextLoanRequest',
          rules: 'required'
        }
      ])
    };
  },
  computed: {
    banks() {
      return this.$store.state.banks;
    },
    selectedUser() {
      return this.loans.selected?.user;
    }
  },
  beforeMount() {},
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(loan) {
      this.loans.selected = loan;
      this.$refs.viewModal.open();
    },
    clear() {
      this.$refs.table.clearSelection();
    },
    selectionUpdate({ selection }) {
      this.selection = selection;
    },
    async getLoans() {
      this.$refs.table.loadAjaxData();
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    openDeclinedModal(loan) {
      this.selectedLoan = loan;
      this.$refs.declineModal.open();
    },
    openOfficerVerifyModal(loan) {
      this.selectedLoan = loan;
      this.$refs.officerVerifyModal.open();
    },
    openStopUserFromRequestingLoanModal(loan) {
      this.selectedLoan = loan;
      this.$refs.stopUserFromRequestingLoanModal.open();
    },
    removeError() {
      this.actionError = '';
    },
    async confirmDecline() {
      this.loading = true;

      await this.sendRequest(
        'admin.loans.personal.decline',
        this.selectedLoan?.id,
        {
          success: () => {
            this.reloadTable();
            this.$refs.declineModal.close();
            this.$success({
              title: 'Operation Successful',
              body: 'Loan Request Declined',
              button: 'Okay'
            });
            this.$emit('success');
          },
          error: error => console.log(error)
        }
      ).finally(() => (this.loading = false));
    },
    async confirmOfficerVerify() {
      this.loading = true;

      await this.sendRequest(
        'admin.loans.personal.officersVerify',
        this.selectedLoan?.id,
        {
          success: () => {
            this.reloadTable();
            this.$refs.officerVerifyModal.close();
            this.$success({
              title: 'Operation Successful',
              body: 'Loan Verified By Account Officer',
              button: 'Okay'
            });
            this.$emit('success');
          },
          error: error => console.log(error)
        }
      ).finally(() => (this.loading = false));
    },
    async confirmStopUserLoanRequest() {
      if (!this.validateForm(this.stopLoanRequestForm)) {
        return false;
      }
      this.loading = true;
      await this.sendRequest(
        'admin.loans.personal.loanRequestStop',
        this.selectedLoan?.id,
        {
          data: {
            comment: this.stopLoanRequestForm.data.comment.value,
            next_loan_request_at: this.stopLoanRequestForm.data.nextLoanRequest
              .value
          },
          success: () => {
            this.reloadTable();
            this.$refs.stopUserFromRequestingLoanModal.close();
            this.selectedLoan = null;
             this.$success({
              title: 'Operation Successful',
              body: 'User has been blocked from requesting loan',
              button: 'Okay'
            });
            this.$emit('success');
          },
          error: () => {
            this.actionError = 'Something went wrong';
          }
        }
      );
      this.loading = false;
    }
  }
};
</script>
